import { gql } from "@apollo/client";
import { GET_WINE_PRICE_RANGES_FRAGMENT } from "./wine";

/**
 * QUERIES
 * */

export const CREDIT_FRAGMENT = gql`
  fragment Credit on CreditObject {
    id
    name
    quantity
    quantityRemaining
    sourcePromoCode {
      isFreeWineForAYearPromo
    }
    preferredWine {
      id
      product {
        name
        slug
        sellingPrice
        productPhotos {
          photoSmall
        }
      }
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      coverPhotoBackgroundColor
    }
    preferredWineClass {
      id
      name
      backgroundColor
    }
    winePriceRange {
      id
      winePriceRangeValue {
        name
        name
        fromPrice
        toPrice
      }
    }
    sourcePromoCode {
      isFreeWineForAYearPromo
    }
  }
`;

export const GET_MEMBER_REFERRAL_CODE = gql`
  query MemberReferralCode {
    me {
      id
      referralCode
    }
  }
`;

export const GET_MEMBER_SHIPPING_PRE_PAIDS = gql`
  query MemberShippingPrePaids {
    me {
      id
      shippingprepaidSet {
        id
        expiryDate
        shippingPrePaidStatus {
          id
        }
      }
    }
  }
`;

export const ALL_ADDONS_FRAGMENT = gql`
  fragment AllAddons on AddOnObject {
    id
    name
    shortName
    settingsHelpText
    productMinPrice
    productMaxPrice
    addToBoxTitle
    addToBoxSubtitle
    addsBottleThatCountsTowardsFreeShippingThreshold
    isStandalone
    landingPageUrl
    memberSubscriptionAddOn {
      status
    }
  }
`;

export const GET_ADDONS = gql`
  query GetAddons {
    allAddOns {
      ...AllAddons
    }
  }
  ${ALL_ADDONS_FRAGMENT}
`;

export const GET_BOX_AND_SPEND = gql`
  ${GET_WINE_PRICE_RANGES_FRAGMENT}
  ${ALL_ADDONS_FRAGMENT}
  query MemberBoxAndSpend {
    allWinePriceRanges {
      ...WinePriceRangesFragment
    }
    allWineClasses {
      id
      name
    }
    allAddOns {
      ...AllAddons
    }
    memberShippingPrePaidsQuery: me {
      id
      shippingprepaidSet {
        id
        expiryDate
        shippingPrePaidStatus {
          id
        }
      }
    }
  }
`;

export const GET_MEMBER_ADDONS = gql`
  query MemberAddons($skipMe: Boolean = false) {
    me @skip(if: $skipMe) {
      id
      email
    }
    allAddOns {
      id
      shortName
      memberSubscriptionAddOn {
        status
      }
    }
  }
`;

export const GET_MEMBER_SUBSCRIPTION_GIVEAWAYS = gql`
  query MemberSubscriptionGiveaways {
    me {
      id
      referralCode
      points
      availableSubscriptionGiveaways {
        id
        code
        url
      }
    }
  }
`;

export const GET_MEMBER_REFERRAL_INFO = gql`
  query MemberReferrals {
    me {
      id
      referralCode
      referrals {
        totalPoints
        totalMates
        referees {
          createdDate
          displayName
        }
      }
      legacyReferrals {
        totalPoints
        totalMates
        referees {
          createdDate
          displayName
        }
      }
    }
  }
`;

export const GET_MEMBER_LAYOUT_INFO = gql`
  query MemberLayoutInfo($skipMe: Boolean = false) {
    minBottlesForFreeShipping
    minBottlesInOrder
    collections(isShownInMenu: true) {
      id
      name
      slug
    }
    me @skip(if: $skipMe) {
      id
      externalUniqueId
      createdDate
      email
      firstName
      points
      hasUnreadRecentNotifications
      hasTakenTheQuiz
      hasDownloadedApp
      pointsBalance
      totalChargedOrdersExcludingGifts
      level {
        rank
        name
      }
      subscription {
        id
        netTotal
        monthCount
        monthFrequency
        subscriptionStatus {
          name
          id
        }
        amountBottles
        amountPresents
      }
      shoppingCart {
        id
        totalNonSubscriptionProductsCost
        shouldWinesBeAddedToSubscription
        total
        discountCode
        amountBottles
        amountPresents
      }
      iterableJwtToken
    }
  }
`;

export const GET_MEMBER_RECENT_NOTIFICATIONS = gql`
  query MemberRecentNotifications {
    me {
      id
      recentNotifications {
        id
        jsonData
        isNotificationRead
        createdDate
        activityType {
          id
        }
      }
    }
  }
`;

export const GET_MEMBER_DASHBOARD_BASIC_INFO = gql`
  query MemberDashboardBasicInfo {
    me {
      id
      firstName
      points
      level {
        rank
      }
      hasTakenTheQuiz
      subscription {
        id
        subscriptionStatus {
          id
        }
      }
    }
  }
`;

export const GET_MEMBER_THANK_YOU_BASIC_INFO = gql`
  query MemberThankYouBasicInfo {
    me {
      id
      subscription {
        activeAddOns: subscribedAddons(status: ACTIVE) {
          id
          shortName
        }
      }
      winequantitySet {
        id
        numberOfBottles
      }
    }
  }
`;

export const GET_MEMBER_REWARD_BASIC_INFO = gql`
  query MemberRewardBasicInfo {
    me {
      id
      pointsBalance
      hasTakenTheQuiz
      shoppingCart {
        id
        shouldWinesBeAddedToSubscription
      }
      level {
        rank
      }
      subscription {
        id
        netTotal
      }
    }
  }
`;

export const GET_MEMBER_BASIC_INFO = gql`
  query MemberBasicInfo {
    me {
      id
      externalUniqueId
      email
      birthDate
      mobileNumber
      gender
      firstName
      lastName
      hasUpdatedPassword
      points
      hasPendingGift
      hasActiveGift
      hasTakenTheQuiz
      createdDate
      subscription {
        id
      }
      user {
        id
      }
    }
  }
`;

export const GET_MEMBER_INFO_ON_LOGIN = gql`
  query MemberBasicInfoOnLogin {
    me {
      id
      externalUniqueId
      email
      firstName
      lastName
      country {
        id
      }
      user {
        id
      }
      shoppingCart {
        id
        shoppingcartitemSet {
          id
          extraInfo
          product {
            id
          }
        }
      }
      subscription {
        id
      }
    }
  }
`;
export const GET_MEMBER_QUIZ_DATA = gql`
  query MemberQuizData {
    me {
      id
      email
      firstName
      hasPendingGift
      hasActiveGift
      subscription {
        id
        monthCount
        subscriptionStatus {
          id
        }
      }
      quizAnswers {
        id
        quizQuestion {
          id
        }
      }
      winequantitySet {
        id
        numberOfBottles
        wineClass {
          id
          name
        }
      }
    }
    allSignupPromoCodes(forceIncludeWinePromoCodes: true) {
      id
      promoCode {
        name
        isFreeWineForAYearPromo
        promocodefreebieSet {
          freebie {
            product {
              name
            }
          }
          quantity
        }
      }
      promotionLabel
      promotionImageQuiz
      promotionImageQuizWebMobileView
      isSoldOut
      isPreorder
      descriptionMarkdown
    }
  }
`;

export const GET_MEMBER_QUIZ_RESULTS = gql`
  query MemberQuizResults {
    me {
      id
      hasPendingGift
      shoppingCart {
        id
        discount
      }
      subscription {
        id
        subscriptionwineSet {
          id
          wine {
            id
            year
            product {
              id
              name
              productPhotos {
                id
                photoSmall
                photoLarge
              }
            }
            wineRegion {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_ACCOUNT_INFO = gql`
  ${CREDIT_FRAGMENT}
  query MemberAccountInfo {
    me {
      id
      email
      birthDate
      mobileNumber
      mobileIsVerified
      hasTakenTheQuiz
      gender
      firstName
      lastName
      hasUpdatedPassword
      createdDate
      shoppingCart {
        id
        shouldWinesBeAddedToSubscription
        shoppingcartitemSet {
          id
          extraInfo
          product {
            id
          }
        }
      }
      winequantitySet {
        id
        numberOfBottles
        wineClass {
          id
          name
        }
        winePriceRange {
          id
          isEnabled
          winePriceRangeValue {
            name
            fromPrice
            toPrice
          }
        }
      }
      paymentmethodSet {
        id
        paymentApiMethodUuid
        isDefault
        cardBrand
        cardLast4
        cardExpiryMonth
        cardExpiryYear
      }
      wineblacklistSet {
        id
        wineBlacklistType {
          id
          name
        }
        objectId
      }
      subscription {
        id
        billingDay
        monthCount
        monthFrequency
        maximumBoxSpend
        subscriptionStatus {
          id
          name
        }
        giftCreditDiscount
        giftRolloverCredit
        discountCredits {
          discountValue
        }
        freeBottleCredits {
          numberOfBottles
          freeWineValue
          winePriceRange {
            id
          }
        }
      }
      shippingAddress {
        id
        firstName
        lastName
        line1
        line2
        company
        state
        postcode
        contactNumber
        city
        state
        extra
        country {
          id
          name
        }
        addressUnavailableInstruction {
          id
          nameShort
          specifyLocation
        }
      }
      contactpreferenceSet {
        id
        contactType {
          id
          name
        }
        contactMethod {
          id
          name
        }
      }
      chooseFreeBottleCredits {
        ...Credit
      }
    }
  }
`;

export const GET_MEMBER_WINE_LISTS = gql`
  query MemberWineLists {
    me {
      id
      winelistSet {
        id
        name
        sortOrder
        isPublic
        wineListType {
          id
        }
      }
    }
  }
`;

export const GET_MEMBER_DISCOUNTS = gql`
  query MemberDiscounts {
    me {
      id
      shoppingCart {
        id
        discount
        discountCode
        discountType {
          id
        }
        isStandaloneAddOnShippingIncluded
        hasPriceRangePromoCodeDiscount
        shouldWinesBeAddedToSubscription
      }
    }
  }
`;

export const GET_MEMBER_SHOPPING_CART = gql`
  ${CREDIT_FRAGMENT}
  query MemberShoppingCart {
    me {
      id
      hasTakenTheQuiz
      pointsBalance
      level {
        rank
      }
      shoppingCart {
        id
        discount
        discountCode
        discountType {
          id
        }
        isStandaloneAddOnShippingIncluded
        total
        totalProductsCost
        totalNonSubscriptionProductsCost
        totalShippingFee
        pointsUsed
        shouldWinesBeAddedToSubscription
        freeBottleDiscount
        amountBottles
        amountPresents
        shoppingcartitemSet {
          id
          quantity
          extraInfo
          isRedeemedWithPoints
          product {
            id
            name
            slug
            sellingPrice
            pointCost
            coverPhotoSmall
            coverPhotoSmallPng
            backgroundColor
            productType {
              id
              name
            }
            productPhotos {
              id
              photoLarge
            }
            wine {
              id
              year
              country {
                id
                name
              }
              wineType {
                id
                name
                wineClass {
                  id
                  name
                }
              }
              wineRegion {
                id
                name
              }
            }
          }
        }
        discountCredits {
          discountValue
        }
        freeBottleCredits {
          numberOfBottles
          freeWineValue
          winePriceRange {
            id
          }
        }
        freebieCredits {
          numberOfUnits
          freebie {
            id
            product {
              id
              name
            }
          }
        }
        cartVatItems {
          name
          quantity
          totalPrice
          totalDiscountedPrice
          totalPointsRedeemed
        }
      }
      chooseFreeBottleCredits(target: CART) {
        ...Credit
      }
      vatItems {
        name
        quantity
        totalPrice
        totalDiscountedPrice
        totalPointsRedeemed
      }
    }
  }
`;

export const GET_MEMBER_SUBSCRIPTION = gql`
  ${CREDIT_FRAGMENT}
  query MemberSubscription {
    me {
      id
      hasPendingGift
      pointsBalance
      level {
        rank
      }
      subscription {
        id
        monthFrequency
        monthCount
        defaultSellingPrice
        defaultTotalBottles
        sellingPrice
        pointsUsedInNextBox
        shippingCost
        holdUntilDate
        billingDay
        nextBoxSummaryTitle
        nextBoxSummarySubTitle
        nextBoxBillingDate
        statusModifiedDate
        freeBottleDiscount
        giftCreditDiscount
        giftRolloverCredit
        maximumBoxSpend
        netTotal
        amountBottles
        amountPresents
        subscriptionStatus {
          id
          name
        }
        activeAddOns: subscribedAddons(status: ACTIVE) {
          id
          name
          shortName
          color
          productMinPrice
          productMaxPrice
          addToBoxTitle
          addToBoxSubtitle
          addsBottleThatCountsTowardsFreeShippingThreshold
          nextBoxImageUrl
          isStandalone
          memberSubscriptionAddOn {
            orderItem {
              id
              productName
              product {
                id
                slug
                name
                coverPhotoSmall
                coverPhotoSmallPng
                backgroundColor
                pointCost
                sellingPrice
                wine {
                  id
                  recipes {
                    id
                    url
                  }
                  wineType {
                    id
                    wineClass {
                      id
                    }
                  }
                }
                freebie {
                  id
                  imageMedium
                }
                productPhotos {
                  id
                  photoSmall
                }
                productType {
                  id
                  name
                }
              }
            }
          }
        }
        skippedAddOns: subscribedAddons(status: SKIP) {
          id
          name
          shortName
          color
          productMinPrice
          productMaxPrice
          addToBoxTitle
          addToBoxSubtitle
          addsBottleThatCountsTowardsFreeShippingThreshold
          nextBoxImageUrl
          isStandalone
        }
        discountCredits {
          discountValue
        }
        freeBottleCredits {
          numberOfBottles
          freeWineValue
          winePriceRange {
            id
          }
        }
        freebieCredits {
          numberOfUnits
          freebie {
            id
            product {
              id
              name
            }
          }
        }
        orderOpenForChanges {
          id
          orderStatus {
            id
            name
          }
          orderCategory {
            id
            name
          }
          orderitemSet {
            id
            productName
            quantityOrdered
            orderItemSource {
              id
            }
            product {
              id
              slug
              name
              coverPhotoSmall
              coverPhotoSmallPng
              backgroundColor
              pointCost
              sellingPrice
              wine {
                id
                recipes {
                  id
                  url
                }
                wineType {
                  id
                  wineClass {
                    id
                  }
                }
              }
              freebie {
                id
                imageMedium
              }
              productPhotos {
                id
                photoSmall
              }
              productType {
                id
                name
              }
            }
          }
        }
        subscriptionnextboxproductSet {
          id
          quantity
          isRedeemedWithPoints
          product {
            id
            name
            slug
            sellingPrice
            pointCost
            coverPhotoSmall
            backgroundColor
            productType {
              id
              name
            }
          }
        }
        subscriptionwineSet {
          id
          quantity
          memberLikelihood
          isRecommended
          boxSlot
          wine {
            id
            year
            shortDescription
            country {
              id
              name
            }
            winePriceRange {
              id
            }
            product {
              id
              name
              slug
              sellingPrice
              coverPhotoSmall
              productPhotos {
                id
                photoSmall
                photoLarge
              }
            }
            wineType {
              id
              name
              quizJustification
              wineClass {
                id
                name
              }
            }
            wineRegion {
              id
              name
            }
          }
          wineQuantity {
            id
            numberOfBottles
            wineClass {
              id
              name
            }
            winePriceRange {
              id
              isEnabled
              winePriceRangeValue {
                name
                toPrice
                fromPrice
              }
            }
          }
        }
      }
      winequantitySet {
        id
        numberOfBottles
        wineClass {
          id
          name
        }
        winePriceRange {
          id
          isEnabled
        }
      }
      chooseFreeBottleCredits(target: BOX) {
        ...Credit
      }
      vatItems {
        name
        quantity
        totalPrice
        totalDiscountedPrice
        totalPointsRedeemed
      }
    }
  }
`;

export const MEMBER_CHECKOUT_INFOS = gql`
  ${CREDIT_FRAGMENT}
  fragment MemberCheckoutInfos on MemberObject {
    id
    email
    firstName
    lastName
    birthDate
    mobileCountryCode
    mobileNumber
    mobileIsVerified
    hasUpdatedPassword
    hasPendingGift
    externalUniqueId
    subscription {
      id
      sellingPrice
      shippingCost
      monthCount
      maximumBoxSpend
      monthFrequency
      subscriptionwineSet {
        id
        quantity
        boxSlot
        wine {
          id
          hasStock
          winePriceRange {
            id
            isEnabled
            isEnabledForWineQuantity
          }
          wineType {
            id
            wineClass {
              id
              name
            }
          }
        }
        wineQuantity {
          id
        }
      }
      activeAddOns: subscribedAddons(status: ACTIVE) {
        id
        name
        shortName
        isStandalone
        productMinPrice
        productMaxPrice
        nextBoxImageUrl
      }
    }
    shippingAddress {
      id
      firstName
      lastName
      line1
      line2
      company
      state
      postcode
      contactNumber
      city
      state
      extra
      country {
        id
        name
      }
      addressUnavailableInstruction {
        id
        nameShort
        specifyLocation
      }
    }
    shoppingCart {
      id
      discount
      discountCode
      discountType {
        id
        name
      }
      referralCode
      discountCreditsTotalValue
      total
      totalProductsCost
      totalShippingFee
      isFraudVerificationRequired
      isStandaloneAddOnShippingIncluded
      freeBottleDiscount
      pointsUsed
      shoppingcartitemSet {
        id
        quantity
        extraInfo
        isRedeemedWithPoints
        product {
          id
          skuCode
          name
          sellingPrice
          pointCost
          coverPhotoSmall
          coverPhotoSmallPng
          productType {
            id
            name
          }
          wine {
            id
          }
        }
      }
      discountCredits {
        discountValue
      }
      freeBottleCredits {
        numberOfBottles
        freeWineValue
        winePriceRange {
          id
        }
      }
      freebieCredits {
        numberOfUnits
        freebie {
          id
          product {
            id
            name
            coverPhotoSmallPng
          }
        }
      }
      autoOptInAddons {
        id
        name
        productMinPrice
        productMaxPrice
      }
      cartVatItems {
        name
        quantity
        totalPrice
        totalDiscountedPrice
        totalPointsRedeemed
      }
    }
    paymentmethodSet {
      id
      paymentApiMethodUuid
      isDefault
      cardBrand
      cardLast4
      cardExpiryMonth
      cardExpiryYear
    }
    shippingprepaidSet {
      id
      shippingPrePaidStatus {
        id
      }
    }
    winequantitySet {
      id
      numberOfBottles
      wineClass {
        id
        name
      }
      winePriceRange {
        id
        isEnabled
        winePriceRangeValue {
          name
          fromPrice
          toPrice
        }
      }
    }
    chooseFreeBottleCredits(target: CART) {
      ...Credit
    }
    vatItems {
      name
      quantity
      totalPrice
      totalDiscountedPrice
      totalPointsRedeemed
    }
  }
`;

export const GET_MEMBER_CHOOSE_FREE_BOTTLE_CREDITS_IN_BOX = gql`
  ${CREDIT_FRAGMENT}
  query MemberChooseFreeBottleCreditsInBox {
    me {
      id
      chooseFreeBottleCredits(target: BOX) {
        ...Credit
      }
    }
  }
`;

export const GET_MEMBER_CHECKOUT_INFO = gql`
  ${MEMBER_CHECKOUT_INFOS}
  query MemberCheckoutInfo {
    minBottlesForFreeShipping
    minBottlesInOrder
    me {
      ...MemberCheckoutInfos
    }
  }
`;

export const GET_MEMBER_PROGRESS_INFO = gql`
  query MemberProgressInfo {
    me {
      id
      points
      firstName
      createdDate
      pointsBalance
      levelCredits
      level {
        id
        rank
        name
        pointsPerBottleRated
        nextLevel {
          id
          rank
          name
          minimumLevelCredits
          coverPhotoLargeUrl
          pointsPerBottleRated
          benefits
          unlockedBenefits
          nextLevel {
            rank
            minimumLevelCredits
          }
        }
      }
    }
    allMemberLevelBenefits {
      benefit
      name
      description
      illustrationUrl
    }
  }
`;

export const GET_MEMBER_DASHBOARD_INFO = gql`
  query MemberDashboardInfo {
    me {
      id
      points
      firstName
      createdDate
      pointsBalance
      currentPointsBadge {
        id
      }
      nextPointsBadge {
        id
        name
        shortDescription
        coverPhotoLargeUrl
        level
        badgerulesSet {
          id
          quantity
        }
        badgebenefitSet {
          creditType {
            id
          }
          freebie {
            id
            imageMedium
            product {
              id
              name
            }
          }
        }
      }
      lockedBadges {
        id
        name
        imageSmallUrl
        points
        level
        shortDescription
      }
      shoppingCart {
        id
        discount
        discountCode
        discountType {
          id
        }
      }
      subscription {
        id
        monthFrequency
        holdUntilDate
        billingDay
        nextBoxSummaryTitle
        nextBoxSummarySubTitle
        subscriptionStatus {
          id
          name
        }
        giftCreditDiscount
        giftRolloverCredit
        discountCredits {
          discountValue
        }
        freeBottleCredits {
          numberOfBottles
          freeWineValue
          winePriceRange {
            id
          }
        }
        freebieCredits {
          numberOfUnits
          freebie {
            id
            product {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_DASHBOARD_PROGRESS_INFO = gql`
  query MemberDashboardProgressInfo {
    me {
      id
      points
      levelCredits
      level {
        id
        rank
        name
        nextLevel {
          id
          rank
          name
          minimumLevelCredits
        }
      }
      nextPointsBadge {
        id
        name
        shortDescription
        coverPhotoLargeUrl
        level
        badgerulesSet {
          id
          quantity
        }
        badgebenefitSet {
          creditType {
            id
          }
          freebie {
            id
            imageMedium
            product {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_DASHBOARD_SUBSCRIPTION_INFO = gql`
  query MemberDashboardSubscriptionInfo {
    me {
      id
      winequantitySet {
        id
        numberOfBottles
      }
      subscription {
        id
        monthFrequency
        nextBoxSummaryTitle
        nextBoxSummarySubTitle
        nextBoxRecommendationsDate
        maximumBoxSpend
        netTotal
        isRecommendationReady
        daysToNextBoxRecommendation
        activeAddOns: subscribedAddons(status: ACTIVE) {
          id
          name
          shortName
          memberSubscriptionAddOn {
            orderItem {
              id
              productName
              product {
                id
                slug
                name
                coverPhotoSmallPng
                productType {
                  id
                }
              }
            }
          }
          subscriptionWidgetImageUrl
        }
        orderOpenForChanges {
          id
        }
        subscriptionStatus {
          id
          name
        }
        subscriptionwineSet {
          id
          quantity
          wine {
            id
            product {
              id
              slug
              productPhotos {
                photoSmall
              }
            }
            wineType {
              id
              wineClass {
                id
              }
            }
          }
          wineQuantity {
            wineClass {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_TASTE_PROFILE = gql`
  query MemberTasteProfileInfo {
    me {
      id
      firstName
      createdDate
      points
      totalBadgesEarned
      totalChargedOrdersExcludingGifts
      totalBottlesOrderedExcludingGifts
      totalWinesRated
      totalWinesReviewed
      hasTakenTheQuiz
      shoppingCart {
        id
        shouldWinesBeAddedToSubscription
      }
      level {
        name
        imageSmallUrl
      }
      tasteProfile {
        id
        isMemberEligibleForTasteProfile
        memberRemainingWineRatingsToUnlockTasteProfile
        quizTastebudProfile {
          id
          tastebudProfileSummaryHtml
          tastebudProfileSweetness {
            id
            name
            level
          }
          tastebudProfileOaky {
            id
            name
            level
          }
          tastebudProfileFruitDriven {
            id
            name
            level
          }
          tastebudProfileBody {
            id
            name
            level
          }
          tastebudProfileTannin {
            id
            name
            level
          }
          tastebudProfileAcidity {
            id
            name
            level
          }
        }
        ratingsTastebudProfile {
          id
          tastebudProfileSummaryHtml
          tanninAverage
          bodyAverage
          oakyAverage
          acidityAverage
          sweetnessAverage
          fruitDrivenAverage
        }
        memberHighlyRatedWines {
          id
          memberWineRatingScore
          hasStock
          hasOtherVintageStock
          winePriceRange {
            id
          }
          product {
            id
            name
            sellingPrice
            coverPhotoSmall
            slug
            lastOrderByMember {
              createdDate
            }
            productPhotos {
              id
              photoSmall
            }
            wine {
              id
            }
          }
          wineType {
            id
            name
            wineClass {
              id
              name
            }
          }
        }
        memberGrapeTypesTriedFromTotal {
          numberItemsTried
          numberItemsAvailable
          numberNewItemsTriedThisYear
        }
        memberHighestRatedGrapeTypes {
          itemId
          itemName
          numberOfRatings
          averageRating
          wineClassId
        }
        memberLowestRatedGrapeTypes {
          itemId
          itemName
          numberOfRatings
          averageRating
          wineClassId
        }
        memberWineProductionMethodsTriedFromTotal {
          numberItemsTried
          numberItemsAvailable
          numberNewItemsTriedThisYear
        }
        memberHighestRatedWineProductionMethods {
          itemId
          itemName
          numberOfRatings
          averageRating
        }
        memberLowestRatedWineProductionMethods {
          itemId
          itemName
          numberOfRatings
          averageRating
        }
        memberMostVisitedCountries {
          itemId
          itemName
          averageRating
          numberOfRatings
          totalBottlesOrdered
        }
        memberMostVisitedWineRegions {
          itemId
          itemName
          averageRating
          numberOfRatings
          totalBottlesOrdered
        }
        memberCountriesVisitedFromTotal {
          numberItemsTried
          numberItemsAvailable
        }
        memberRegionsVisitedFromTotal {
          numberItemsTried
          numberItemsAvailable
        }
      }
    }
  }
`;

export const GET_MEMBER_RATING_TASTE_PROFILE_BY_WINE_CLASS = gql`
  query MemberRatingTasteProfileByWineClass($wineClassId: Int!) {
    memberRatingTastebudProfileByWineClass(wineClassId: $wineClassId) {
      ratingTastebudProfile {
        bodyAverage
        oakyAverage
        tanninAverage
        acidityAverage
        sweetnessAverage
        fruitDrivenAverage
        alcoholPercentageAverage
        numberOfRatings
      }
      numberOfRatings
    }
  }
`;

export const GET_MEMBER_TASTEBUD_PROFILE_INFO = gql`
  query MemberTastebudProfileInfo {
    me {
      id
      tasteProfile {
        id
        isMemberEligibleForTasteProfile
        memberRemainingWineRatingsToUnlockTasteProfile
        quizTastebudProfile {
          id
          tastebudProfileSummaryHtml
          tastebudProfileSweetness {
            id
            name
            level
          }
          tastebudProfileOaky {
            id
            name
            level
          }
          tastebudProfileFruitDriven {
            id
            name
            level
          }
          tastebudProfileBody {
            id
            name
            level
          }
          tastebudProfileTannin {
            id
            name
            level
          }
          tastebudProfileAcidity {
            id
            name
            level
          }
        }
        ratingsTastebudProfile {
          id
          tastebudProfileSummaryHtml
          tanninAverage
          bodyAverage
          oakyAverage
          acidityAverage
          sweetnessAverage
          fruitDrivenAverage
        }
      }
    }
  }
`;

export const GET_SIMILAR_WINE_RECOMMENDATION = gql`
  query MemberSimilarWineRecommendation(
    $memberId: Int!
    $wineClassId: Int!
    $winePriceRangeId: Int!
    $excludeWineIds: [Int]
  ) {
    memberSimilarWineRecommendation(
      memberId: $memberId
      wineClassId: $wineClassId
      winePriceRangeId: $winePriceRangeId
      excludeWineIds: $excludeWineIds
    ) {
      id
      memberLikelihood
      product {
        id
        name
        slug
        sellingPrice
        coverPhotoSmall
        productType {
          id
        }
        productPhotos {
          id
          photoSmall
        }
        wine {
          id
        }
      }
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
    }
  }
`;

export const GET_REFERRAL_DISCOUNT = gql`
  query ReferralDiscount {
    referralDiscount @client {
      referralCode
      giveawayCode
    }
  }
`;

export const GET_ALL_WINE_BLACKLIST_TYPES = gql`
  query AllWineBlacklistTypes {
    allWineBlacklistTypes {
      id
      name
      choices {
        id
        name
      }
    }
  }
`;

export const GET_ALL_MEMBER_EARNED_BADGES = gql`
  query MemberAllEarnedBadges($memberId: Int, $excludeZeroPointBadges: Boolean) {
    memberAllEarnedBadges(memberId: $memberId, excludeZeroPointBadges: $excludeZeroPointBadges) {
      id
      name
      badgeNameWhenEarned
      points
      badgePointsWhenEarned
      level
      dateTimeEarned
      isEarned
    }
  }
`;

export const GET_MEMBER_BOX_INCLUSIONS_INFO = gql`
  query MemberBoxInclusionsInfo {
    me {
      id
      isReceivingTastingCards
    }
  }
`;

export const GET_ALL_MEMBER_REWARD_LEVELS = gql`
  query AllMemberLevels {
    allMemberLevels {
      rank
      name
      tier {
        name
        slug
        color
      }
      minimumLevelCredits
      pointsPerBottleRated
      coverPhotoMediumUrl
      benefits
      imageSmallUrl
      nextLevel {
        rank
        name
        minimumLevelCredits
      }
    }
  }
`;

export const GET_ALL_MEMBER_REWARD_LEVELS_AND_BENEFITS = gql`
  query AllMemberLevelsAndBenefits {
    allMemberLevels {
      rank
      name
      tier {
        name
        slug
        color
        illustrationUrl
      }
      minimumLevelCredits
      pointsPerBottleRated
      coverPhotoMediumUrl
      benefits
      imageSmallUrl
      nextLevel {
        rank
        name
        minimumLevelCredits
      }
    }
    allMemberLevelBenefits {
      benefit
      name
      description
      illustrationUrl
      howItWorksMarkdown
    }
  }
`;

/**
 * MUTATIONS
 * */

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreateMemberPaymentMethod($input: CreateMemberPaymentMethodInput!) {
    createMemberPaymentMethod(input: $input) {
      paymentMethod {
        id
        paymentApiMethodUuid
        isDefault
        cardBrand
        cardLast4
        cardExpiryMonth
        cardExpiryYear
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation DeleteMemberPaymentMethod($input: DeletePaymentMethodInput!) {
    deleteMemberPaymentMethod(input: $input) {
      isDeleted
      errors {
        messages
        field
      }
    }
  }
`;

export const UPDATE_MEMBER_PAYMENT_METHOD = gql`
  mutation UpdateMemberPaymentMethod($input: UpdateMemberPaymentMethodInput!) {
    updateMemberPaymentMethod(input: $input) {
      paymentMethod {
        id
        paymentApiMethodUuid
        isDefault
        cardBrand
        cardLast4
        cardExpiryMonth
        cardExpiryYear
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const CREATE_PAYMENT_SETUP_INTENT = gql`
  mutation CreatePaymentSetupIntent($input: CreatePaymentSetupIntentInput!) {
    createPaymentSetupIntent(input: $input) {
      clientSecret
      errors {
        messages
        field
      }
    }
  }
`;

export const REGISTER_MEMBER_PAYMENT_METHOD = gql`
  mutation RegisterMemberPaymentMethod($input: RegisterMemberPaymentMethodInput!) {
    registerMemberPaymentMethod(input: $input) {
      errors {
        messages
        field
      }
    }
  }
`;

export const CREATE_CONTACT_PREFERENCE = gql`
  mutation CreateMemberContactPreference($input: CreateContactPreferenceInput!) {
    createMemberContactPreference(input: $input) {
      contactPreference {
        id
        contactType {
          id
        }
        contactMethod {
          id
        }
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const DELETE_CONTACT_PREFERENCE = gql`
  mutation DeleteMemberContactPreference($input: DeleteContactPreferenceInput!) {
    deleteMemberContactPreference(input: $input) {
      isDeleted
      errors {
        messages
        field
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUpMutation($input: MemberMutationInput!) {
    signUp(input: $input) {
      id
      externalUniqueId
      firstName
      lastName
      userId
      clientMutationId
      errors {
        messages
        field
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation($email: String!) {
    resetPassword(email: $email) {
      success
      error # (DEV-1608) Remove and just use errors below
      errors {
        messages
        field
      }
    }
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation SetNewPassword(
    $password: String!
    $passwordConfirmation: String!
    $token: String!
    $uidb64: String!
  ) {
    setNewPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      token: $token
      uidb64: $uidb64
    ) {
      success
      error
    }
  }
`;

export const UPDATE_MEMBER_SHIPPING_ADDRESS = gql`
  mutation UpdateMemberShippingAddress($input: ShippingAddressInput!) {
    updateMemberShippingAddress(input: $input) {
      shippingAddress {
        id
        firstName
        lastName
        company
        line1
        line2
        postcode
        contactNumber
        city
        state
        extra
        country {
          id
        }
        addressUnavailableInstruction {
          id
          nameShort
          authorityToLeave
        }
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const UPDATE_MEMBER_ACCOUNT_DETAILS = gql`
  mutation UpdateMemberAccountDetails($input: MemberMutationInput!) {
    updateMember(input: $input) {
      id
      email
      birthDate
      mobileNumber
      gender
      firstName
      lastName
      errors {
        messages
        field
      }
    }
  }
`;

export const REQUEST_MOBILE_NUMBER_VERIFICATION_CODE = gql`
  mutation RequestMobileNumberVerificationCode($input: RequestMobileNumberVerificationCodeInput!) {
    requestMobileNumberVerificationCode(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const VERIFY_MOBILE_NUMBER = gql`
  mutation VerifyMobileNumber($input: VerifyMobileNumberInput!) {
    verifyMobileNumber(input: $input) {
      isVerified
      errors {
        field
        messages
      }
    }
  }
`;

export const RESET_WINE_PREFERENCE = gql`
  mutation ResetMemberWineQuantities($input: ResetMemberWineQuantitiesInput!) {
    resetMemberWineQuantities(input: $input) {
      allWineQuantities {
        id
        wineClass {
          id
        }
        winePriceRange {
          id
        }
        numberOfBottles
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_BOX_AND_SPEND = gql`
  mutation UpdateBoxAndSpend($input: UpdateBoxAndSpendInput!) {
    updateBoxAndSpend(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const REMOVE_WINE_QUANTITIES = gql`
  mutation RemoveWineQuantities {
    removeWineQuantities {
      isSuccessful
      errors {
        field
        messages
      }
      me {
        subscription {
          defaultTotalBottles
          maximumBoxSpend
        }
        winequantitySet {
          numberOfBottles
          wineClass {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_WINE_RATING = gql`
  mutation UpdateWineRating($input: WineRatingInput!) {
    updateWineRating(input: $input) {
      wineRating {
        id
        liked
        totalUpvotes
        totalDownvotes
        note
        score
        pointsEarned
        randomVariableRewardPoints
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_TOP_MEMBERS = gql`
  query TopMembers($limit: Int, $offset: Int) {
    topMembers(limit: $limit, offset: $offset) {
      id
      firstName
      lastName
      points
      avatarThumbnailUrl
      slug
    }
  }
`;

export const VALIDATE_REFERRAL_CODE = gql`
  mutation ValidateReferralCode($input: ValidateReferralCodeInput!) {
    validateReferralCode(input: $input) {
      isValid
      discountValue
      promotionImageCheckout
      promotionImageCheckoutMobile
      promotionImageQuizResults
      promotionDescriptionMarkdown
      promotionValue
      referralCodeBenefits {
        creditType {
          id
        }
        freeWineValue
        freebie {
          product {
            name
          }
        }
        quantity
      }
      validationMessage
      errors {
        field
        messages
      }
    }
  }
`;

export const VALIDATE_GIVEAWAY_CODE = gql`
  mutation validateGiveawayCode($input: ValidateGiveawayCodeInput!) {
    validateGiveawayCode(input: $input) {
      isValid
      errors {
        field
        messages
      }
    }
  }
`;

export const INVITE_FRIEND = gql`
  mutation InviteFriend($input: InviteFriendInput!) {
    inviteFriend(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const SET_REFERRAL_DISCOUNT = gql`
  mutation SetReferralDiscount($referralCode: String, $giveawayCode: String) {
    setReferralDiscount(referralCode: $referralCode, giveawayCode: $giveawayCode) @client {
      referralDiscount @client {
        referralCode
        giveawayCode
      }
    }
  }
`;

export const CREATE_WINE_BLACKLIST_RULE = gql`
  mutation CreateWineBlacklistRule($input: WineBlacklistRuleInput!) {
    createWineBlacklistRule(input: $input) {
      wineBlacklist {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_WINE_BLACKLIST_RULE = gql`
  mutation DeleteWineBlacklistRule($input: WineBlacklistRuleInput!) {
    deleteWineBlacklistRule(input: $input) {
      isDeleted
      errors {
        field
        messages
      }
    }
  }
`;

export const SEND_GIVEAWAY_INVITE = gql`
  mutation SendGiveawayInvite($input: SendGiveawayInviteInput!) {
    sendGiveawayInvite(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const ADD_WINE_TO_WINE_LIST = gql`
  mutation AddWineToWineList($input: UpdateWineInWineListInput!) {
    addWineToWineList(input: $input) {
      wineList {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const REMOVE_WINE_FROM_WINE_LIST = gql`
  mutation RemoveWineFromWineList($input: UpdateWineInWineListInput!) {
    removeWineFromWineList(input: $input) {
      wineList {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_OR_CREATE_WINE_LIST = gql`
  mutation UpdateOrCreateWineList($input: WineListInput!) {
    updateOrCreateWineList(input: $input) {
      wineList {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_WINE_LIST = gql`
  mutation DeleteWineList($input: DeleteWineListInput!) {
    deleteWineList(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationAsRead($input: MarkNotificationsAsReadInput!) {
    markNotificationsAsRead(input: $input) {
      isSuccessful
      errors {
        messages
        field
      }
    }
  }
`;

export const APPLY_REACTIVATION_CREDIT = gql`
  mutation ApplyReactivationCredit($input: ApplyReactivationCreditInput!) {
    applyReactivationCredit(input: $input) {
      isSuccessful
      errors {
        messages
        field
      }
    }
  }
`;

export const GET_ALL_MEMBER_SUBSCRIPTION_REFERRALS = gql`
  query allMemberSubscriptionReferrals($memberId: Int) {
    allMemberSubscriptionReferrals(memberId: $memberId) {
      firstName
      createdDate
    }
  }
`;

export const GET_MEMBER_ONBOARDING_CHAPTERS = gql`
  query MemberOnboardingChapters {
    memberUnlockedOnboardingChapters {
      id
      name
      number
      isCompleted
      isRewardClaimed
      isInProgress
    }
  }
`;

export const GET_MEMBER_ONBOARDING_ITEMS_IN_CHAPTER = gql`
  query MemberOnboardingItemsInChapter($chapterNumber: Int!) {
    memberOnboardingChapter(chapterNumber: $chapterNumber) {
      id
      name
      number
      isCompleted
      onboardingsectionSet {
        id
        name
        shortName
        number
        isCompleted
        onboardingitemSet {
          id
          name
          description
          number
          isCompleted
        }
      }
    }
  }
`;

export const MARK_ONBOARDING_CHAPTER_AS_COMPLETED = gql`
  mutation MarkOnboardingChapterAsCompleted($input: OnboardingStatusInput!) {
    markOnboardingChapterAsCompleted(input: $input) {
      chapter {
        id
        isCompleted
        name
        number
        onboardingsectionSet {
          id
          name
          shortName
          number
          isCompleted
          onboardingitemSet {
            id
            name
            description
            number
            isCompleted
          }
        }
      }
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const MARK_ONBOARDING_ITEM_AS_COMPLETED = gql`
  mutation MarkOnboardingItemAsCompleted($input: OnboardingStatusInput!) {
    markOnboardingItemAsCompleted(input: $input) {
      chapter {
        id
        isCompleted
        name
        number
        onboardingsectionSet {
          id
          name
          shortName
          number
          isCompleted
          onboardingitemSet {
            id
            name
            number
            description
            isCompleted
          }
        }
      }
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const CLAIM_ONBOARDING_CHAPTER_REWARD = gql`
  mutation ClaimOnboardingChapterReward($input: OnboardingStatusInput!) {
    claimChapterReward(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
      badge {
        id
        points
      }
    }
  }
`;

export const TOGGLE_TASTING_CARD_PREFERENCES = gql`
  mutation ToggleTastingCardPreferenceMutation {
    toggleTastingCardsPreference {
      isSuccessful
      errors {
        field
        messages
      }
      me {
        id
        isReceivingTastingCards
      }
    }
  }
`;

export const SIGN_UP_TO_STANDALONE_ADDON = gql`
  mutation SignUpToStandaloneAddOn($input: SignUpToStandaloneAddOnInput!) {
    signUpToStandaloneAddOn(input: $input) {
      isSuccessful
      accessToken
      refreshToken
      me {
        id
        subscription {
          subscribedAddons {
            id
            name
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_MEMBER_LEVELS_UPDATE = gql`
  query MemberLevelCreditsUpdates {
    me {
      id
      levelCreditsUpdates {
        createdDate
        newLevel {
          id
          name
        }
        amount
        source
        sourceBadge {
          name
          level
        }
      }
    }
  }
`;

export const GET_MEMBER_REDEEMED_ITEMS = gql`
  query MemberOrderItemsRedeemedWithPoints {
    memberOrderItemsRedeemedWithPoints {
      createdDate
      productPointCost
      productName
      quantityOrdered
    }
  }
`;

export const GET_MEMBER_LEVEL_AND_ALL_BENEFITS = gql`
  query MemberLevelWithAllBenefits($id: Int!) {
    memberLevel(id: $id) {
      rank
      name
      minimumLevelCredits
      pointsPerBottleRated
      coverPhotoLargeUrl
      unlockedBenefits
      nextLevel {
        rank
        minimumLevelCredits
      }
      previousLevel {
        pointsPerBottleRated
      }
    }
    allMemberLevelBenefits {
      benefit
      name
      description
      illustrationUrl
    }
  }
`;

export const GET_MEMBER_CANCELLATION_INFO = gql`
  query MemberCancellationInfo {
    me {
      id
      pointsBalance
      firstName
      subscription {
        id
        monthFrequency
        nextBoxSummaryTitle
        subscriptionStatus {
          id
          name
        }
      }
      paymentmethodSet {
        id
      }
    }
  }
`;

export const GET_MEMBER_POINTS_BALANCE = gql`
  query MemberPointsBalanceInfo {
    me {
      id
      pointsBalance
    }
  }
`;

export const GET_MEMBER_CREATED_DATE = gql`
  query MemberCreatedDate {
    me {
      id
      createdDate
    }
  }
`;

export const GET_CREDIT = gql`
  ${CREDIT_FRAGMENT}
  query GetCredit($id: Int!) {
    credit(id: $id) {
      ...Credit
    }
  }
`;
export const GET_MEMBER_SHOPPING_CART_BASIC_INFO = gql`
  query MemberShoppingCartBasicInfo {
    me {
      id
      shoppingCart {
        id
        shouldWinesBeAddedToSubscription
      }
    }
  }
`;

/**
 * MUTATIONS
 * */

export const UPDATE_MEMBER_CREDIT = gql`
  ${CREDIT_FRAGMENT}
  mutation UpdateMemberCredit($input: UpdateMemberCreditInput!) {
    updateMemberCredit(input: $input) {
      credit {
        ...Credit
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const RECOMMEND_WINE_FOR_CREDIT = gql`
  ${CREDIT_FRAGMENT}
  mutation RecommendWineForCredit($input: RecommendWineForCreditInput!) {
    recommendWineForCredit(input: $input) {
      credit {
        ...Credit
      }
      errors {
        field
        messages
      }
    }
  }
`;

/**
 * Resolvers, Defaults & Type definitions
 * https://www.apollographql.com/docs/link/links/state
 * */
export const resolverReferralDiscount = {
  defaults: {
    referralDiscount: {
      referralCode: null,
      giveawayCode: null,
      __typename: "ReferralDiscountObjectStore",
    },
  },
  resolvers: {
    Mutation: {
      setReferralDiscount: async (obj, args, { cache }) => {
        const data = {
          __typename: "Store",
          referralDiscount: {
            __typename: "ReferralDiscountObjectStore",
            ...args,
          },
        };
        await cache.writeQuery({
          query: GET_REFERRAL_DISCOUNT,
          data,
        });
        return data;
      },
    },
  },
};
